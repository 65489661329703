import request from './index'

export const userList = (data) => {
  return request({
    method: 'GET',
    url: '/user/userinfoList',
    params: data
  })
}

export const userAdd = (data) => {
  return request({
    method: 'POST',
    url: '/user/userInsert',
    data: data
  })
}

export const userLogin = (data) => {
  return request({
    method: 'POST',
    url: '/user/login',
    data: data
  })
}

export const userUpdate = (data) => {
  return request({
    method: 'PUT',
    url: '/user/userinfoUpdate',
    data: data
  })
}

export const userStatus = (data) => {
  return request({
    method: 'PUT',
    url: '/user/status',
    data: data
  })
}
