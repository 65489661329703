<template>
  <div class="login-page">
    <b-card>
      <div class="text-center mb-4">
        <img
          src="@/assets/images/logo-single.png"
          width="60"
        />
        <h4>Welcome to Soocel!</h4>
        <p>Please sign-in to your account</p>
      </div>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group>
            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="user.email"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  v-model="user.password"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Enter you password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form-group>

          <b-button
            variant="primary"
            type="submit"
            size="lg"
            block
            @click.prevent="doLogin"
          >Sign In</b-button>

        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import { userLogin } from '@/api/user'

export default {
  data () {
    return {
      user: {}
    }
  },
  methods: {
    async doLogin () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // do login here
          this.loading = true
          const response = await userLogin(this.user)
          if (response.data.token) {
            // on success
            window.localStorage.setItem('user-token', response.data.token)
            window.localStorage.setItem('user-name', response.data.signUserInfo.name)
            window.localStorage.setItem('user-email', response.data.signUserInfo.email)
            window.localStorage.setItem('user-id', response.data.signUserInfo.id)
            window.localStorage.setItem('user-rights', response.data.signUserInfo.rights)
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/dashboard')
            }
          } else {
            this.$bvToast.toast(response.msg, {
              title: 'Login error!',
              variant: 'danger',
              solid: true
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .login-page {
    min-height: 100vh;
  }
  .card {
    margin: 100px auto 0;
    width: 450px;
  }
  .card .card-body {
    padding: 40px 30px;
  }
  h4 {
    font-size: 20px;
    color: #5e5873;
    margin: 20px 0 5px;
  }
  p {
    color: #5e5873;
    margin: 0 0 10px;
    font-size: 14px;
  }
</style>
